import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";

import LoginGoogle from "../authentication/LoginGoogle";

class Sidebar extends Component {
  state = {
    load: false,
    isLogined: localStorage.getItem("isLogined"),
  };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    const { load } = this.state;

    let profilInfos = JSON.parse(localStorage.getItem("profilInfos"));

    return (
      <nav className="sidebar " id="sidebar">
        <ul className="nav">
          {/*   { (load  && profilInfos) &&  */}
          {profilInfos && (
            <>
              <li className="nav-item nav-profile">
                {/** HEADER MENU - HEADER MENU - HEADER MENU - HEADER MENU - HEADER MENU */}
                <div className="brand-logo">
                  <img
                    alt="logo"
                    src={`${process.env.PUBLIC_URL}/logo.png`}
                    width="200"
                  />
                </div>

                <a
                  href="!#"
                  className="nav-link"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="nav-profile-image">
                    <img src={profilInfos.imageUrl} alt="profile" />
                    <span className="login-status online"></span>{" "}
                    {/* change to offline or busy as needed */}
                  </div>
                  <div className="nav-profile-text">
                    <span className="font-weight-bold mb-2">{}</span>

                    <div>{profilInfos.name}</div>
                  </div>
                </a>
              </li>
            </>
          )}

          {/*



          email: "demotestns@infa-formation.com"
          familyName: "SIRIUS"
          givenName: "Nicolas"
          googleId: "112700105769691624477"
          imageUrl: "https://lh3.googleusercontent.com/a/AATXAJyCFhf1LDAfWGGP6FVq50ZIxiAfvWUE3WOzgQGD=s96-c"
          name: "Nicolas SIRIUS"

          */}

          <hr />

          {/** ELEMENTS MENU - ELEMENTS MENU - ELEMENTS MENU */}
          <li
            className={
              this.isPathActive("/admin/contrats")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/admin/contrats">
              <i className="mdi mdi-menu menu-icon"></i>
              <span className="menu-title">Formulaires</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/admin/parametres")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/admin/parametres">
              <i className="mdi mdi-settings menu-icon"></i>
              <span className="menu-title">Parametres Formulaires</span>
            </Link>
          </li>

          {/*

          <li className={ this.isPathActive('/admin/media') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/admin/media">
            <i className="mdi mdi-folder-multiple-image menu-icon"></i>
              <span className="menu-title">Upload Contrats</span>
           
            </Link>
          </li>
        */}

          {console.log(
            "useradvances",
            localStorage.getItem("statutUserAdvanced")
          )}
          {(localStorage.getItem("statutUserAdvanced") === "true" ||
            localStorage.getItem("statutAdmin") === "true") && (
            <li
              className={
                this.isPathActive("/admin/groupes")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/admin/groupes">
                <i className="mdi mdi-account-card-details menu-icon"></i>
                <span className="menu-title">Groupes</span>
              </Link>
            </li>
          )}

          {localStorage.getItem("statutAdmin") === "true" && (
            <>
              <li
                className={
                  this.isPathActive("/admin/administrateurs")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/admin/administrateurs">
                  <i className="mdi mdi-account-key menu-icon"></i>
                  <span className="menu-title">Administrateurs</span>
                </Link>
              </li>
            </>
          )}

          <li className="nav-item">
            <Link className="nav-link">
              <i className="mdi mdi-logout menu-icon"></i>
              <li>
                <center>
                  <LoginGoogle />
                </center>
              </li>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    this.setState({
      load: true,
    });
  }
}

export default withRouter(Sidebar);
